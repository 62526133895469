import store from '@/store'
import { foundryRequest } from '@/config/authConfig';
import {
  mdiAlertCircleOutline,
  mdiChartTimelineVariant,
  mdiCheckCircleOutline,
  mdiClose,
  mdiContentSaveOutline,
  mdiDownloadCircleOutline,
  mdiEmailOutline,
} from '@mdi/js'
import { ref, watch, getCurrentInstance } from '@vue/composition-api'

export default function usePOsList() {
  const vm = getCurrentInstance().proxy
  const poListTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'Trending', value: 'trending', sortable: false },
    { text: 'PROJECT', value: 'project' },
    { text: 'TYPE', value: 'project_type' },
    { text: 'OFFICE', value: 'office' },
    { text: 'SUPPLIER', value: 'supplier' },
    { text: 'AMOUNT', value: 'amount' },
    { text: 'SERVICE DATE', value: 'service_date' },
    { text: 'VALIDATED DATE', value: 'validated_date' },
  ]

  const searchQuery = ref('')
  const dateRangeFilter = ref([])
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: parseInt(process.env.VUE_APP_ITEMS_PER_PAGE),
  })
  const totalPOListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])


  const fetchPOs = async (projects='') => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    if (searchQuery.value === null) searchQuery.value = '';
    if (statusFilter.value === null) statusFilter.value = '';
    loading.value = true
    store
    .dispatch('app-po/fetchPOs', {
      search: searchQuery.value,
      status:statusFilter.value,
      options: JSON.stringify(options.value),
      page: options.value.page,
      projects
    })
    .then(response => {
        const { results, count } = response.data

        poListTable.value = results
        totalPOListTable.value = count
        loading.value = false

    })
    .catch(error => {
        console.log(error)
    })
  }

  const submitSearch = (event) => {
    if (event) {
      event.preventDefault()
    }
    loading.value = true

    fetchPOs()
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePOStatusVariantAndIcon = status => {
    if (status === 'VALID') return { variant: 'success', icon: mdiCheckCircleOutline, label: 'Validated & Sent' }
    if (status === 'DRAFT') return { variant: 'secondary', icon: mdiContentSaveOutline, label: 'Draft' }
    if (status === 'CANLD') return { variant: 'error', icon: mdiClose, label: 'Cancelled' }

    return { variant: 'secondary', icon: mdiContentSaveOutline, label: 'Draft' }
  }

  const resolvePOProjectTypeVariant = type => {
    if (type === 'Quantitative') return { variant: 'primary',  label: 'Quantitative' }
    if (type === 'Qualitative') return { variant: 'warning', label: 'Qualitative' }
    if (type === 'Consultancy') return { variant: 'info', label: 'Consultancy' }

    return { variant: 'secondary',  label: 'Other' }
  }

  return {
    tableColumns,
    searchQuery,
    dateRangeFilter,
    options,
    poListTable,
    statusFilter,
    totalPOListTable,
    loading,
    selectedTableData,
    fetchPOs,
    resolvePOStatusVariantAndIcon,
    resolvePOProjectTypeVariant,
    submitSearch,
  }
}
