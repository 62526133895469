<template>

  <section>
    
    <v-card
      class="mb-6">
      <v-card-title>
        Filters
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <v-autocomplete
            v-model="statusFilter"
            :items="statusOptions"
            item-text="label"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            placeholder="Select Status"
            class="po-list-status mr-4"
          ></v-autocomplete>
          <v-menu
            ref="datepicker"
            v-model="datepickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                outlined
                dense
                hide-details
                :prepend-inner-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                v-on="on"
                class="po-list-status"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRangeFilter"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="datepickerMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="saveDate"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
          

      </v-card-text>
    </v-card>
    <v-card id="po-list">
    <!-- search -->
    
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
          <v-form
              @submit="submitSearch($event)"
              v-if="!projectData.job_number"
          >
              <v-text-field
              v-model="searchQuery"
              placeholder="Search POs"
              outlined
              hide-details
              dense
              clearable
              class="po-list-search me-3"
              :append-icon="icons.mdiSend"
              @click:append="submitSearch"
              @click:append-outer="submitSearch"
              @click:clear="submitSearch"
              >
              </v-text-field>
          </v-form>
        </div>

      <v-spacer></v-spacer>

        <div class="d-flex align-center pb-5">
          <!-- actions -->
          <!-- <v-select
            v-model="selectedAction"
            label="Actions"
            single-line
            outlined
            dense
            :items="actions"
            hide-details
            :disabled="Boolean(!selectedTableData.length)"
            class="po-list-actions me-3"
          ></v-select> -->

          <!-- create po -->
          <v-btn
            color="primary"
            class="me-3"
            @click="createPO"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Create Purchase Order</span>
          </v-btn>
        </div>

      
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="poListTable"
      :options.sync="options"
      :server-items-length="totalPOListTable"
      :loading="loading"
      show-select
      class="text-no-wrap"
      :footer-props="{
        'items-per-page-options':[options.itemsPerPage],
        'disable-items-per-page': true,
      }"
    >
      <!-- trending header -->
      <template #[`header.trending`]>
        <v-icon size="22">
          {{ icons.mdiTrendingUp }}
        </v-icon>
      </template>

      <!-- id -->
      <template #[`item.id`]="{item}">
        <div
          class="font-weight-medium text-decoration-none primary--text cursor-pointer"
          @click="clickPO(item.id)"
          
        >
          #{{ item.id }}
      </div>
      </template>

      <!-- status  -->
      <template #[`item.trending`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              size="30"
              :color="resolvePOStatusVariantAndIcon(item.status).variant"
              :class="`v-avatar-light-bg ${resolvePOStatusVariantAndIcon(item.status).variant}--text`"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                size="18"
                :color="resolvePOStatusVariantAndIcon(item.status).variant"
              >
                {{ resolvePOStatusVariantAndIcon(item.status).icon }}
              </v-icon>
            </v-avatar>
          </template>
          <p class="mb-0">
            {{ resolvePOStatusVariantAndIcon(item.status).label }}
          </p>
          <p class="mb-0">
            Serv. Date: {{ item.service_date }}
          </p>
          <p class="mb-0">
            Pay. Terms: {{ item.payment_terms }}
          </p>
        </v-tooltip>
      </template>

      <!-- client -->
      <template #[`item.supplier`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            :color="stringToHslColor(item.supplier_contact.name)"
            :class="`white--text`"
            size="30"
          >
            <span
              class="font-weight-medium text-xs"
            >{{ avatarText(item.supplier_contact.name) }}</span>
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.supplier.name }}</span>
            <span class="text-xs">{{ item.supplier_contact.email }}</span>
          </div>
        </div>
      </template>

      <!-- Project -->
      <template #[`item.project`]="{item}">
        <span>
          {{ item.project.ges_bva ? `${item.project.job_number} / ${item.project.ges_bva}` : item.project.job_number}}
        </span>
      </template>

      
      <!-- Office -->
      <template #[`item.office`]="{item}">
        <span>
          {{ item.project.office ? `${item.project.office.name}` : ''}}
        </span>
      </template>
      
      <!-- Project Type -->
      <template #[`item.project_type`]="{item}">
        <v-chip
            small
            :color="resolvePOProjectTypeVariant(item.project.project_type).variant"
            :class="`${resolvePOProjectTypeVariant(item.project.project_type).variant}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.project.project_type }}
          </v-chip>
      </template>


      <!-- total amount -->
      <!-- <template #[`item.amount`]="{item}">
        {{item.amount.toLocaleString('fr-FR', { style: 'currency', currency: item.currency.name})}}
      </template> -->

      
      <template #[`item.amount`]="{item}">
          <div class="d-flex flex-column">
              <div class="d-flex align-center">
              <span class="primary--text font-weight-medium">{{ item.amount.toLocaleString('fr-FR') }}</span>
              <sup class="primary--text text-muted">{{item.currency.name}}</sup>
              </div>
          </div>
      </template>

      <!-- Balance -->
      <template #[`item.balance`]="{item}">
        <span v-if="checkType(item.balance) !== 'number'">
          {{ item.balance }}
        </span>

        <!-- chip -->
        <v-chip
          v-else
          small
          :color="item.total !== item.balance ? 'success':'error'"
          :class="`v-chip-light-bg ${item.total !== item.balance ? 'success':'error'}--text font-weight-semibold`"
        >
          {{ item.total !== item.balance ? 'Paid':'Unpaid' }}
        </v-chip>
      </template>

      <!-- date -->
      <template #[`item.service_date`]="{item}">
        <span class="text-no-wrap">{{ item.service_date }}</span>
      </template>

    </v-data-table>
  </v-card>
  </section>
  
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownloadOutline,
  mdiPencilOutline,
  mdiContentCopy,
  mdiSend,
  mdiCalendar
} from '@mdi/js'

import { onUnmounted, computed, ref, watch, getCurrentInstance } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { stringToHslColor } from '@core/utils'
import store from '@/store'
import router from '@/router'

// store module
import poStoreModule from '../poStoreModule'

// composition function
import usePOsList from './usePOList'
import usePO from '../usePO'

export default {
  props: {
    projectData: {
      type: Object,
      required: false,
      default: () => { return {}}
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const INVOICE_APP_STORE_MODULE_NAME = 'app-po'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, poStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      poListTable,
      searchQuery,
      dateRangeFilter,
      tableColumns,
      options,
      statusFilter,
      totalPOListTable,
      loading,
      selectedTableData,
      resolvePOStatusVariantAndIcon,
      resolvePOProjectTypeVariant,
      submitSearch,
      fetchPOs,
    } = usePOsList()

    const {
      statusOptions,
    } = usePO()


    const actions = ['Delete', 'Edit', 'Send']
    const datepickerMenu = ref(false)

    const selectedAction = ref('')

    // const actionOptions = [
    //   { title: 'Download', icon: mdiDownloadOutline },
    //   { title: 'Edit', icon: mdiPencilOutline },
    //   { title: 'Delete', icon: mdiDeleteOutline },
    //   { title: 'Duplicate', icon: mdiContentCopy },
    // ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    const clickPO = (poId) => {
      switch(router.currentRoute.name) {
        case 'project-view-po':
          router.push({name:'project-view-po-id', params: { poId: poId }})
          break;
        case 'po':
          router.push({name:'po-view', params: { poId: poId } })
          break;
        default:
          router.push({name:'po-view', params: { poId: poId } })
      } 
    }

    const createPO = () => {
      switch(router.currentRoute.name) {
        case 'project-view-po':
          router.push({name:'project-view-po-add', params: {projectData: props.projectData} })
          break;
        case 'po':
          router.push({name:'po-add', params: {projectData: props.projectData} })
          break;
        default:
          router.push({name:'po-add', params: {projectData: props.projectData} })
      } 
    }
    
    const saveDate = () => {
      vm.$refs.datepicker.save(dateRangeFilter)
    }

    watch([statusFilter, options], () => {
      const job_number = props.projectData.job_number || ''
      // start loading
      loading.value = true
      selectedTableData.value = []
      fetchPOs(job_number)
    })

    const dateRangeText = computed(() =>{
      return  dateRangeFilter.value.join(' ~ ')
    })

    return {
      datepickerMenu,
      dateRangeFilter,
      dateRangeText,
      tableColumns,
      searchQuery,
      statusOptions,
      statusFilter,
      options,
      totalPOListTable,
      poListTable,
      loading,
      actions,
      selectedTableData,
      selectedAction,
      clickPO,
      createPO,

      checkType,
      avatarText,
      resolvePOStatusVariantAndIcon,
      resolvePOProjectTypeVariant,
      stringToHslColor,
      submitSearch,
      saveDate,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiSend,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
}
</style>
